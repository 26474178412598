
$('.inscription-ok').hide();

function inscriptionSuccess(response){
    $('.section-form__form').fadeOut(function() {
        $('.inscription-ok').fadeIn();
    });
}


function inscriptionError(response){
    $('form').find('.error-message').hide();

    jQuery.each(response.specificData, function( i, field ) {
        if (typeof(field) == "string" && $('form #'+ field).length) {
            $('form #'+ field).parent().addClass('error');    
        } else {
            if (typeof(field) == "string"){
                $('form input[name="'+ field + '"], form select[name="'+ field + '"]').parent().addClass('error');
            }else{
                $('form input[name="'+ field.fieldName + '"], form select[name="'+ field.fieldName + '"]').parent().addClass('error');
                if (field.fieldName.includes('code_') && $('form input[name="'+ field.fieldName + '"]').val().length > 0) {
                    $('form input[name="'+ field.fieldName + '"]').parent().find('.error-message').html(field.text);
                    $('form input[name="'+ field.fieldName + '"]').parent().find('.error-message').show();
                }
                console.log(field.fieldName);
                
                if (field.fieldName == 'host') {
                    $('form select[name="'+ field.fieldName + '"]').parent().find('.error-message').html(field.text);
                    $('form select[name="'+ field.fieldName + '"]').parent().find('.error-message').show();
                }
                
            }
        }
    });
}

function addGuest(){
    var $div = $('.clone').find('.guest').clone();
    $('#numGuests').val(parseInt($('#numGuests').val())+1);
    $('#guests').append($div);
    $('#guests .section-form__guest-block:last-child').find('.form-group--select select').select2();
    if ( $('#guests .section-form__guest-block:last-child .select2').length > 1 ) {
        $('#guests .section-form__guest-block:last-child .select2:last-child').remove();
    }
    
    $('.btn-remove').show();
   
    if ($('#guests .guest').length >= 25) {
        $('.addGuest').prop('disabled', true);
    }
    
    $('#guests .section-form__guest-block:last-child').find('.btn-remove').data('guest', $('#guests .guest').length);
    $('#guests .section-form__guest-block:last-child').find('.guest-name').prop('name','name_' + $('#guests .guest').length);
    $('#guests .section-form__guest-block:last-child').find('.guest-surname').prop('name','surname_' + $('#guests .guest').length);
    $('#guests .section-form__guest-block:last-child').find('.guest-email').prop('name','email_' + $('#guests .guest').length);
    $('#guests .section-form__guest-block:last-child').find('.guest-dni').prop('name','dni_' + $('#guests .guest').length);
    $('#guests .section-form__guest-block:last-child').find('.guest-typeVisit').prop('name','typeVisit_' + $('#guests .guest').length);
    $('#guests .section-form__guest-block:last-child').find('.guest-code').prop('name','code_' + $('#guests .guest').length);
    return false;
}


function removeGuest(element){
    var guestBlock = $('#guests .section-form__guest-block');    
    $('#numGuests').val(parseInt($('#numGuests').val())-1);
    $('#guests').children('.section-form__guest-block:nth-child('+$(element).data('guest')+')').remove();

    $('#guests .section-form__guest-block').each(function(i) {
        console.log(i);
        $(this).find('.btn-remove').data('guest', (i+1));
        $(this).find('.guest-name').prop('name','name_' + (i+1));
        $(this).find('.guest-surname').prop('name','surname_' + (i+1));
        $(this).find('.guest-email').prop('name','email_' + (i+1));
        $(this).find('.guest-dni').prop('name','dni_' + (i+1));
        $(this).find('.guest-typeVisit').prop('name','typeVisit_' + (i+1));
        $(this).find('.guest-code').prop('name','code_' + (i+1));
    });

    return false;
}

var recaptchaOk = function(token) {

    var $form = $('form.use_recaptcha');
    var $inputRecaptcha = $form.find('input[name="g-recaptcha-response"]');

    if(!$inputRecaptcha.length) {
        $inputRecaptcha = $('<input type="hidden" name="g-recaptcha-response" />');
        $form.append($inputRecaptcha);
    }

    $inputRecaptcha.val(token);

    $form.submit();
}